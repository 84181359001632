import React from "react";
import { withStyles } from "@material-ui/core/styles";

const particleParams = {
  particles: {
    number: {
      value: 75
    },
    size: {
      value: 3
    }
  }
};

const styles = {
  "@keyframes AnimationName": {
    "0%": {
      backgroundPosition: "0% 50%"
    },
    "50%": {
      backgroundPosition: "100% 50%"
    },
    "100%": {
      backgroundPosition: "0% 50%"
    }
  },
  backgroundSettings: {
    animation: "AnimationName 45s ease infinite",
    background: "linear-gradient(to right, #62b747, #368a79,  #abcc4f)", // green color, what sarabjeet wanted
    backgroundSize: "600% 600%",
    position: "fixed",
    zIndex: -1,
    width: "100%",
    height: "100%"
  }
};

const Background = props => {
  const { classes } = props;
  return (
    // <Particles params={particleParams} className={classes.backgroundSettings} />
    <div className={classes.backgroundSettings}></div>
  );
};

export default withStyles(styles)(Background);
