const ClientOauth = require("client-oauth2");

let host = window.location.host;
// const authUrl =
//   host === "disco.cisco.com"
//     ? "https://cloudsso.cisco.com/as/authorization.oauth2"
//     : "https://cloudsso-test.cisco.com/as/authorization.oauth2";

/* BEGIN PKCE FLOW STUFF */
const clientId =
  host === "disco.cisco.com" ? "DINDMYEIEI47IBR13ONS" : "DIQU3SNN9169TNON5X9C";

const authUrl =
  host === "disco.cisco.com"
    ? "https://sso-dbbfec7f.sso.duosecurity.com/oidc/DINDMYEIEI47IBR13ONS"
    : "https://sso-345c0691.sso.duosecurity.com/oidc/DIQU3SNN9169TNON5X9C";

const scopes = ["openid email profile"];

export function generateCodeVerifier(length) {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const values = crypto.getRandomValues(new Uint8Array(length));
  return values.reduce((acc, x) => acc + possible[x % possible.length]);
}

async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);

  return window.crypto.subtle.digest("SHA-256", data);
}

function base64Encode(input) {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/, "");
}

export async function generateCodeChallenge(codeVerifier) {
  const hashed = await sha256(codeVerifier);
  return base64Encode(hashed);
}

function getCodeVerifier() {
  return localStorage.getItem("code_verifier");
}

function setCodeVerifier(codeVerifier) {
  localStorage.setItem("code_verifier", codeVerifier);
}

export const pkceSetup = async (ciscoAuth) => {
  if (!ciscoAuth) {
    window.location = "/";
    return;
  }
  const codeVerifier = generateCodeVerifier(64);
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  setCodeVerifier(codeVerifier);
  window.location = ciscoAuth.code.getUri({
    query: { code_challenge: codeChallenge, code_challenge_method: "S256" },
  });
};

export const getUserName = () => {
  const username = localStorage.getItem("username");
  return username;
};

export const setUserName = (username) => {
  localStorage.setItem("username", username);
};

export const pkceVerify = async () => {
  const code = new URLSearchParams(window.location.search).get("code");
  if (!code) {
    return;
  }
  const redirectUri = window.location.href.replace(window.location.search, ""); // remove query params
  const codeVerifier = getCodeVerifier();

  return fetch(authUrl + "/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      client_id: clientId,
      code,
      code_verifier: codeVerifier,
      grant_type: "authorization_code",
      redirect_uri: redirectUri,
      // state: "abcetc",
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export function getJWTPayload(token) {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
}
/* END PKCE FLOW STUFF */

export const ciscoAuth = new ClientOauth({
  clientId: clientId,
  authorizationUri: authUrl + "/authorize",
  redirectUri:
    (host.indexOf("localhost") > -1 ? "http://" : "https://") + host + "/auth",
  scopes,
});
export const ciscoAuthYubikey = new ClientOauth({
  clientId: clientId,
  authorizationUri: authUrl + "/authorize",
  redirectUri:
    (host.indexOf("localhost") > -1 ? "http://" : "https://") +
    host +
    "/auth-yubikey",
  scopes,
});

export const ciscoAuthDigiPass = new ClientOauth({
  clientId: clientId,
  authorizationUri: authUrl + "/authorize",
  redirectUri:
    (host.indexOf("localhost") > -1 ? "http://" : "https://") +
    host +
    "/auth-digipass",
  scopes,
});

export const ciscoAuthFeitian = new ClientOauth({
  clientId: clientId,
  authorizationUri: authUrl + "/authorize",
  redirectUri:
    (host.indexOf("localhost") > -1 ? "http://" : "https://") +
    host +
    "/auth-feitian",
  scopes,
});

export function getToken() {
  const token = localStorage.getItem("oauth");
  return token;
}

export function setToken(token) {
  return localStorage.setItem("oauth", token);
}

export const checkIfTokenExpired = () => {
  const token = getToken();
  if (!token) {
    return true;
  }
  const expiredTime = getJWTPayload(token).exp;
  const currentTime = Math.floor(Date.now() / 1000);
  const expired = currentTime > expiredTime;
  return expired;
};

export const authorizeCheck = async (ciscoAuth) => {
  const response = await pkceVerify(ciscoAuth);
  if (!response) {
    return [false, ""];
  }

  if (!response.id_token) {
    return [false, ""];
  }

  const parsedJwtToken = getJWTPayload(response.id_token);
  const accessToken = response.access_token;

  if (!accessToken) {
    return [false, ""];
  }

  const user = parsedJwtToken.UserName;

  if (!user) {
    return [false, ""];
  }

  setToken(accessToken);
  return [true, user];
};
