import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LinearProgress from "@material-ui/core/LinearProgress";
import basePath from "../../../utils";
import yubikeyProcess from "../../../assets/yubikeyProcess.svg";
import yubikeyC from "../../../assets/yubikey-C.svg";
import { getToken } from "../../Oauth/Oauth";

const styles = {
  root: {
    margin: "0 auto",
    maxWidth: "750px"
  },
  textContent: {
    marginBottom: "10px",
    fontSize: "large"
  },
  successMsg: {
    marginBottom: "10px",
    fontSize: "large",
    color: "green"
  },
  errMsg: {
    marginBottom: "10px",
    fontSize: "large",
    color: "red"
  },
  button: {
    marginRight: 10
  },
  displayContainer: {
    display: "flex",
    flexDirection: "column"
  },
  contentContainer: {
    minWidth: "200px",
    paddingTop: "15px",
    paddingRight: "30px",
    paddingLeft: "30px",
    fontSize: "large"
  },
  duoImgContainer: {
    width: "40%",
    minWidth: "150px",
    maxWidth: "350px",
    height: "40%",
    marginTop: 30,
    marginLeft: 30
  },
  yubikeyImgContainer: {
    width: "40%",
    minWidth: "150px",
    maxWidth: "350px",
    marginLeft: "5%"
  },
  registerButton: {
    paddingTop: "10px"
  },
  backButton: {
    paddingTop: 50,
    paddingBottom: 20,
    margin: "0 auto"
  },
  inputWrapper: {
    display: "flex",
    margin: "0 auto"
  },
  icon: {
    marginRight: 5,
    height: 20,
    width: 20
  },
  responseContainer: {
    display: "flex"
  },
  responseImage: {
    width: 50,
    height: 50,
    paddingLeft: 10
  }
};

const Input = props => {
  return (
    <TextField
      id="outlined-multiline-flexible"
      label="Registration Request Data"
      multiline
      rows="3"
      rowsMax="8"
      value={props.value}
      onChange={props.onChange}
      error={!props.value}
      margin="normal"
      variant="outlined"
      autoFocus
      style={{ width: 500 }}
    />
  );
};

const OtpInput = props => {
  return (
    <TextField
      id="outlined-with-placeholder"
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      error={!props.value}
      margin="normal"
      variant="outlined"
      autoFocus
      style={{ width: 500 }}
    />
  );
};

const Content = props => {
  const { classes } = props;
  const [otpCode, setOtpCode] = React.useState("");
  const [b64Code, setB64Code] = React.useState("");
  // const [validCode, setValidCode] = React.useState(false);
  const [extraUI, setExtraUI] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const [firstApiResponse, setFirstApiResponse] = React.useState("");
  const [secondApiResponse, setSecondApiResponse] = React.useState("");

  const handleApiResponse = response => {
    const successful = ["AR", "FRU"];
    if (successful.includes(response.status)) {
      return (
        <>
          <Typography variant="h5" className={classes.successMsg}>
            Congratulations, you&apos;re all set!
          </Typography>
          <div className={classes.responseContainer}>
            <Typography className={classes.successMsg} gutterBottom>
              <p className={classes.successMsg}>{response.message}</p>
            </Typography>
          </div>
        </>
      );
    }
    if (response.status === "UA") {
      return (
        <>
          <Typography variant="h5" className={classes.errMsg}>
            Unsuccessful Yubikey Registration
          </Typography>
          <p className={classes.errMsg}>{response.message}</p>
        </>
      );
    }
    if (response.status === "EXPN") {
      return (
        <>
          <Typography variant="h5" className={classes.errMsg}>
            Invalid Registration Request Data
          </Typography>
          <p className={classes.errMsg}>{response.message}</p>
        </>
      );
    }
    if (response.status === "AUR") {
      return (
        <>
          <Typography variant="h5" className={classes.errMsg}>
            Another User is already registered to this Yubikey
          </Typography>
          <p className={classes.errMsg}>{response.message}</p>
        </>
      );
    }
    if (response.status === "ISN") {
      return (
        <>
          <Typography variant="h5" className={classes.errMsg}>
            Yubikey Not Found
          </Typography>
          <p className={classes.errMsg}>{response.message}</p>
        </>
      );
    }
    if (response.status === "ERR") {
      return (
        <>
          <Typography variant="h5" className={classes.errMsg}>
            Something went Wrong
          </Typography>
          <p className={classes.errMsg}>{response.message}</p>
        </>
      );
    }
    return <p className={classes.errMsg}>{response.message}</p>;
  };

  const AdditionalSteps = () => {
    const env = localStorage.getItem("environment");
    let macUrl = "https://disco-test.cisco.com/downloads/yubitoken-mac.zip";
    let winUrl = "https://disco-test.cisco.com/downloads/yubitoken-win.zip";
    let linuxUrl = "https://disco-test.cisco.com/downloads/yubitoken-linux.zip";

    if (env === "prod") {
      macUrl = "https://disco.cisco.com/downloads/yubitoken-mac.zip";
      winUrl = "https://disco.cisco.com/downloads/yubitoken-win.zip";
      linuxUrl = "https://disco.cisco.com/downloads/yubitoken-linux.zip";
    }

    return (
      <>
        <Typography className={classes.textContent}>
          Please follow below instructions to generate "Registration Request
          Data&quot;"{" "}
        </Typography>
        <Typography>
          <ul>
            <li>
              Download the registration tool for{" "}
              <b>
                <a
                  href={macUrl}
                  // href="https://disco-test.cisco.com/downloads/yubitoken-mac.zip"
                  // href="https://disco.cisco.com/downloads/yubitoken-mac.zip"
                  rel="noopener noreferrer"
                >
                  MacOS
                </a>
              </b>{" "}
              /{" "}
              <b>
                <a
                  href={winUrl}
                  // href="https://disco-test.cisco.com/downloads/yubitoken-win.zip"
                  // href="https://disco.cisco.com/downloads/yubitoken-win.zip"
                  rel="noopener noreferrer"
                >
                  Windows
                </a>
              </b>{" "}
              /{" "}
              <b>
                <a
                  href={linuxUrl}
                  // href="https://disco-test.cisco.com/downloads/yubitoken-linux.zip"
                  // href="https://disco.cisco.com/downloads/yubitoken-linux.zip"
                  rel="noopener noreferrer"
                >
                  Linux
                </a>
              </b>{" "}
              and unzip the downloaded package
            </li>
            <li>
              Go into the unzipped folder and run the <b>yubitoken</b>{" "}
              (MacOS/Linux) or <b>yubitoken.exe</b> (Windows){" "}
            </li>
            <li>
              On MacOS, you may have to allow the app by going into System
              Preferences and Security & Privacy
            </li>
            <li>Paste "Registration Request Data" below:</li>
          </ul>
        </Typography>
        <div className={classes.inputWrapper}>
          <Input
            value={b64Code}
            onChange={({ target: { value } }) => setB64Code(value)}
            // onChange={({ target: { value } }) => checkRegistrationData(value)}
            maskChar=""
            label="FOB Serial Number"
            verifyFunc={!b64Code}
          />
          <img
            src={yubikeyC}
            alt="duo fob"
            className={classes.duoImgContainer}
          />
        </div>
        <div className={classes.registerButton}>
          <Button
            onClick={() => checkRegistrationData(b64Code)}
            disabled={!b64Code}
            variant="contained"
            color="primary"
          >
            Register
          </Button>
        </div>
        <br />
        {progressBar(loading)}
        {handleApiResponse(secondApiResponse)}
      </>
    );
  };

  const progressBar = isLoading => {
    return isLoading ? (
      <div>
        <LinearProgress />
      </div>
    ) : (
      <></>
    );
  };

  const handleNewYubikey = codeb64 => {
    setSecondApiResponse("");
    setLoading(true);
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` }
    };
    axios
      .post(
        `${basePath}new-yubikey`,
        {
          token: getToken(),
          otp: codeb64,
          yubikeySerial: otpCode // New line to pass otp parameter
        },
        config
      )
      .then(response => {
        setLoading(false);
        setSecondApiResponse(response.data);
      });
  };

  const handleExistingYubikey = code => {
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` }
    };
    axios
      .post(
        `${basePath}yubikey`,
        {
          token: getToken(),
          otp_code: code
        },
        config
      )
      .then(response => setFirstApiResponse(response.data));
  };

  const checkYubikeyStatus = code => {
    setLoading(true);
    setFirstApiResponse("");
    setSecondApiResponse("");
    const config = {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { otp_code: code }
    };
    axios.get(`${basePath}yubikey`, config).then(response => {
      setLoading(false);
      if (response.data.status === false) {
        setExtraUI(true);
      } else {
        handleExistingYubikey(code);
      }
    });
  };

  const checkInput = value => {
    setOtpCode(value);
    // number of characters of the one time password
    if (otpCode.length === 43) {
      checkYubikeyStatus(otpCode);
      if (extraUI) {
        setExtraUI(false);
      }
    }
  };

  const checkRegistrationData = value => {
    setB64Code(value);
    handleNewYubikey(value);
    // alert("check request data");
    // number of characters of the registration request data
    // alert(b64Code.length);
    // if (b64Code.length === 344) {
    //  setValidCode(true);
    // handleNewYubikey(b64Code)
    // } else {
    // setDispRegister(false);
    // }
  };

  return (
    <div className={classes.displayContainer}>
      <Paper className={classes.root}>
        <div className={classes.contentContainer}>
          <Typography variant="h5" gutterBottom>
            Begin Yubikey OTP Registration
          </Typography>
          <Typography className={classes.textContent}>
            Make sure the keyboard layout is configured to QWERTY(US-English).
            <br />
            Insert Yubikey into the USB port of your computer and then Tap the
            Yubikey.
          </Typography>
          <div className={classes.inputWrapper}>
            <OtpInput
              value={otpCode}
              onChange={({ target: { value } }) => checkInput(value)}
              maskChar=""
              label="Tap your Yubikey!"
              verifyFunc={!otpCode}
            />
            <img
              className={classes.yubikeyImgContainer}
              src={yubikeyProcess}
              alt="yubikey pressed"
            />
          </div>
          <div className={classes.registerButton}>
            {/* <Button
              onClick={() => checkYubikeyStatus(props.cec, otpCode)}
              disabled={!otpCode}
              variant="contained"
              color="primary"
            >
              Begin
            </Button> */}
          </div>

          {extraUI ? <></> : progressBar(loading)}
          {handleApiResponse(firstApiResponse)}
          {extraUI ? <AdditionalSteps /> : <></>}
        </div>
      </Paper>
      <div className={classes.backButton}>
        <Button variant="contained" color="secondary" href="/">
          <DashboardIcon className={classes.icon} />
          back to dashboard
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Content);
