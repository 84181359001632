import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { authorizeCheck, ciscoAuthYubikey, setUserName } from "./Oauth";

const AuthRecieverYubikey = () => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = async () => {
      const [check, user] = await authorizeCheck(ciscoAuthYubikey);
  
      if (check) {
        setUserName(user);
        setAuthorized(true);
      }
  
    };

  useEffect(() => {
    authorize();
  }, []);

  return authorized ? <Redirect to="/yubikey" /> : <></>;
};

export default AuthRecieverYubikey;