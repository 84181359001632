import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropsBox from "./PropsBox";
import duoKeyImage from "../../assets/duoKey.svg";
import yubiKeyImage from "../../assets/yubikey.svg";
import feitianKeyImage from "../../assets/feitian1.JPG";

const styles = {
  tile: {
    margin: "0px 16px",
    textAlign: "center",
    minWidth: "275px",
    maxWidth: "280px"
  },  
  choiceContainer: {
    paddingTop: "10px"
  }
};

const Choice = props => {
  const { classes } = props;
  const content = [
    {
      link: "/yubikey",
      image: yubiKeyImage,
      title: "Yubikey OTP",
      width: 200,
      height: 175
    },
    {
      link: "/digipass",
      image: duoKeyImage,
      title: "Digipass",
      width: 200,
      height: 175
    },
    {
      link: "/feitian",
      image: feitianKeyImage,
      title: "Feitian Keys",
      width: 200,
      height: 175,
      important: 'true'
    }
  ];
  return (
    <div className={classes.choiceContainer}>
      <Grid container justifyContent="center">
        {content.map((tile, index) => (
          <Grid
            item
            xs={6}
            className={classes.tile}
            key={`grid-item${index * 1.01}`}
          >
            <PropsBox
              title={tile.title}
              link={tile.link}
              image={tile.image}
              width={tile.width}
              height={tile.height}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Choice);
