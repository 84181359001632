import React from "react";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import Digipass from "./components/Register/Digipass/Digipass";
import RegisterChoice from "./components/Register/RegisterChoice";
import Edit from "./components/EditProfile/Edit";
import Yubikey from "./components/Register/Yubikey/Yubikey";
import AuthReciever from "./components/Oauth/AuthReciever";
import AuthRecieverYubikey from "./components/Oauth/AuthRecieverYubikey";
import AuthRecieverDigipass from "./components/Oauth/AuthRecieverDigipass";
import AuthRecieverFeitian from "./components/Oauth/AuthRecieverFeitian";
import FeitianKey from "./components/Register/FeitianKey/FeitianKey";
import PasswordLess from "./components/PasswordLess/PasswordLess";

const App = () => {
  const currentUrl = window.location.href;
  console.log(`=============${window.location.host}`);
  if (currentUrl.indexOf("localhost") > -1) {
    // console.log("local");
    localStorage.setItem("environment", "local");
  } else if (
    currentUrl.indexOf("dev1") > -1 ||
    currentUrl.indexOf("dev") > -1
  ) {
    // console.log("dev");
    localStorage.setItem("environment", "dev");
  } else if (
    currentUrl.indexOf("test") > -1 ||
    currentUrl.indexOf("test1") > -1 ||
    currentUrl.indexOf("test2") > -1
  ) {
    // console.log("stage");
    localStorage.setItem("environment", "stage");
  } else {
    // console.log("prod");
    localStorage.setItem("environment", "prod");
  }

  /* let env = localStorage.getItem('environment');
  if(env==='local1'){
    localStorage.setItem("redirectBase", "http://localhost:3000");
    localStorage.setItem("oauthUrl", "https://cloudsso-test.cisco.com/as/authorization.oauth2");
  }
  else if(env==='local'){
    localStorage.setItem("redirectBase", "https://disco-dev.cisco.com");
    localStorage.setItem("oauthUrl", "https://cloudsso-test.cisco.com/as/authorization.oauth2");
  }
  else if(env==='stage') {
    localStorage.setItem("redirectBase","https://disco-test.cisco.com");
    localStorage.setItem("oauthUrl", "https://cloudsso-test.cisco.com/as/authorization.oauth2");
  } 
  else if(env==='prod'){
    localStorage.setItem("redirectBase","https://disco.cisco.com");
    localStorage.setItem("oauthUrl", "https://cloudsso.cisco.com/as/authorization.oauth2");
  } */
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/edit-info" component={Edit} />
      <Route path="/register-choice" component={RegisterChoice} />
      <Route path="/auth" component={AuthReciever} />
      <Route path="/auth-digipass" component={AuthRecieverDigipass} />
      <Route path="/auth-yubikey" component={AuthRecieverYubikey} />
      <Route path="/auth-feitian" component={AuthRecieverFeitian} />
      <Route path="/digipass" component={Digipass} />
      <Route path="/yubikey" component={Yubikey} />
      <Route path="/feitian" component={FeitianKey} />
      <Route path="/PasswordLess" component={PasswordLess} />
    </Switch>
  );
};

export default App;
