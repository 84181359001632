import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Background from "../../Common/Background";
import Content from "./Content";
import Title from "../Title";
import { checkIfTokenExpired, ciscoAuthFeitian, getToken, getUserName, pkceSetup } from "../../Oauth/Oauth";

const styles = {
  root: {
    textAlign: "center",
    paddingTop: 50
  }
};

const FeitianKey = props => {
  const { classes } = props;
  const [auth, setAuth] = useState(false);
  const [cec, setCec] = useState("");
  
  const env = localStorage.getItem('environment');
  let authUrl = "https://cloudsso-test.cisco.com/idp/userinfo.openid";
  if(env==='prod'){
    authUrl = "https://cloudsso.cisco.com/idp/userinfo.openid";
  }
  
  const getCec = () => {
    const didItExpire = checkIfTokenExpired();
    if (didItExpire) {
      refreshToken();
      return;
    }
    setCec(getUserName());
    return getUserName();
  };

  const redirect = async () => {
      if (!getToken()) {
        await pkceSetup(ciscoAuthFeitian);
      } else {
        setAuth(true);
        getCec();
      }
  };

  const refreshToken = async () => {
    await pkceSetup(ciscoAuthFeitian);
  };

  useEffect(() => {
    redirect();
  }, []);

  return (
    <div>
      <Background />
      <div>
        <div className={classes.root}>
          <Title message="Register a new Feitian Key" />
        </div>
        <Content cec={cec} />
        <br></br>
      </div>
    </div>
  );
};

export default withStyles(styles)(FeitianKey);