import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { authorizeCheck, ciscoAuthFeitian, setUserName } from "./Oauth";

const AuthRecieverFeitian = () => {
  const [authorized, setAuthorized] = useState(false);

 const authorize = async () => {
    const [check, user] = await authorizeCheck(ciscoAuthFeitian);

    if (check) {
      setUserName(user);
      setAuthorized(true);
    }

  };

  useEffect(() => {
    authorize();
  }, []);

  return authorized ? <Redirect to="/feitian" /> : <></>;
};

export default AuthRecieverFeitian;