import React from "react";
import ReactDOM from "react-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#638944"
      // main: '#132A13'
    },
    secondary: {
      main: "#fff"
      // main: '#132A13'
    },
    tertiary: {
      main: "#000"
    }
  },
  typography: {
    fontFamily: "'Nunito Sans', 'sans-seris'",
    textTransform: "none"
  }
});

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Router>,
  document.getElementById("root")
);
