import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { authorizeCheck, ciscoAuth, pkceSetup, setUserName } from "./Oauth";

const AuthReciever = () => {
  const [authorized, setAuthorized] = useState(false);

  const authorize = async () => {
    const [check, user] = await authorizeCheck(ciscoAuth);

    if (check) {
      setUserName(user);
      setAuthorized(true);
    } else {
      // window.location.href = window.location.href.replace(window.location.search, "");
      // pkceSetup(ciscoAuth);
    }

  };

  useEffect(() => {
    authorize();
  }, []);

  return authorized ? <Redirect to="/register-choice" /> : <></>;
};

export default AuthReciever;
