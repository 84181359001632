import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowIcon from "@material-ui/icons/ArrowRightAlt";
//import focus from "../../assets/editFocusNew.svg";
import focus from "../../assets/editFocusUP.png";

const styles = {
  "@keyframes fadeIn": {
    from: {
      transform: "scale(1.1)"
    }
  },
  mainCon: {
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 800,
    padding: "0 20px"
  },
  button: {
    margin: "0 auto",
    transform: "scale(0.97)",
    animation: "fadeIn 1s infinite alternate"
  },
  buttonContainer: {
    position: "relative"
  },
  image: {
    width: "100%",
    margin: "0 auto"
  },
  imagenew: {
    width: "40%",
    margin: "0 auto"
  },
  icon: {
    marginLeft: 5
  }
};
const Focus = props => {
  const { classes } = props;
  const env = localStorage.getItem('environment');
  //let editLink = "https://cloudsso-test.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso-test.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP";
  //let editLink = "https://sso-345c0691.sso.duosecurity.com/saml2/sp/DIHYEDY4QYRWJMW40SK0/sso";
  let editLink = "https://myduo-test.cisco.com";

  if (env === 'prod') {
    //editLink = "https://cloudsso.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://sso.cisco.com/autho/apps/duo/index.html";
    //editLink = "https://sso-dbbfec7f.sso.duosecurity.com/saml2/sp/DIPBSMTWIMXUX4CESYFQ/sso";
    editLink = "https://myduo.cisco.com";
  } else {
    // console.log("not prod");
  }

  return (
    <div className={classes.mainCon}>
      <img src={focus} alt="edit-info" className={classes.imagenew} />
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          size="medium"
          color="secondary"
          href={editLink}
        // href="https://cloudsso.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP&TargetResource=https://sso.cisco.com/autho/apps/duo/index.html"
        //href="https://cloudsso-test.cisco.com/mfa/pidlogout.html?redirectTo=https://cloudsso-test.cisco.com/idp/startSSO.ping?PartnerSpId=DUO_SP"
        >
          continue
          <ArrowIcon className={classes.icon} Delete />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Focus);
